import React, { FC } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  DateTimeInput,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import HospitalRoomInput from "../../components/inputs/HospitalRoomInput";
import { convertJsToSql } from "../../utils/date";
import bookings from "./";
import useStyles from "./shared/useStyles";
import { OrderActions } from "./util";

const BookingEditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="Revise"
      disabled={props.pristine}
      transform={(data) => ({
        ...data,
        action: OrderActions.REVISE,
      })}
    />
  </Toolbar>
);

export const BookingEdit: React.FC<EditProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <CrudBreadcrumb
        resourceName={bookings.name}
        mode="revise"
        resourceId={props.id}
      />
      <Edit {...props} title={`Booking #${props.id} Revise`}>
        <SimpleForm toolbar={<BookingEditToolbar />}>
          <h2>Details</h2>
          <DateTimeInput source="date" parse={convertJsToSql} />
          <ReferenceInput
            source="consultant_id"
            reference="consultants"
            sort={{ field: "full_name", order: "asc" }}
          >
            <AutocompleteInput optionText="full_name" />
          </ReferenceInput>
          <ReferenceInput
            source="surgeon_id"
            reference="surgeons"
            sort={{ field: "full_name", order: "asc" }}
          >
            <AutocompleteInput optionText="full_name" />
          </ReferenceInput>
          <ReferenceInput
            source="hospital_id"
            reference="hospitals"
            sort={{ field: "name", order: "asc" }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <HospitalRoomInput source="hospital_room_id" />
          <ReferenceInput source="procedure_id" reference="procedures">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput source="remarks" />

          <h2>Instruments & Implants</h2>
          <ArrayInput source="items" label="Instrument & Implants">
            <SimpleFormIterator className={classes.instrument}>
              <ReferenceInput
                label="Name"
                source="product_id"
                reference="products"
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <NumberInput source="quantity" label="Quantity" min="1" />
            </SimpleFormIterator>
          </ArrayInput>

          <h2>Additional Items</h2>
          <TextInput
            multiline
            source="additional_items"
            placeholder="Enter the additional items that are not in the Instrument and Implant lists"
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
