import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  useMutation,
  useNotify,
  useQueryWithStore,
  useRefresh,
  useUnselectAll,
} from "ra-core";
import React from "react";
import { deliveryActions } from "../util";

interface Props {
  selectedIds?: number[];
}
interface AutoCompleteProps {
  full_name: string;
  id?: number;
}
const AssignMultipleDriverDialog: React.FC<Props> = (props) => {
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const { selectedIds } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const { data: drivers } = useQueryWithStore({
    type: "getList",
    resource: "drivers",
    payload: {
      sort: { field: "full_name", order: "asc" },
      pagination: { page: 1, perPage: 1000 },
    },
  });
  const [selectedDriver, setSelectedDriver] = React.useState<AutoCompleteProps>(
    {
      full_name: "",
    }
  );

  const handleClose = () => setOpen(false);

  const [handleAccept] = useMutation(
    {
      type: "updateMany",
      resource: "deliveries",
      payload: {
        data: {
          data: {
            action: deliveryActions.ASSIGN,
            driver_id: selectedDriver.id,
          },
          ids: selectedIds ?? [],
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        refresh();
        notify(`Successfully assign a Driver to multiple Deliveries.`, "info");
        unselectAll("deliveries");
        setOpen(false);
      },
      onFailure: (error) => {
        notify(`Error: ${error.message}`, "warning");
        setOpen(true);
      },
    }
  );

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} size={"small"}>
        Assign Driver
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Assign Driver</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have selected <strong>{selectedIds?.length} deliveries</strong>{" "}
            to be assigned to a driver. Please select a driver.
          </DialogContentText>
          <Autocomplete
            disableClearable
            id="select-driver"
            options={drivers}
            getOptionLabel={(driver: { full_name: string }) => driver.full_name}
            renderInput={(params) => <TextField {...params} label="Driver" />}
            onChange={(event, value: any) => setSelectedDriver(value)}
            value={selectedDriver}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Assign Driver to {selectedIds?.length} Deliveries
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignMultipleDriverDialog;
